.visible {
    opacity: 1;
}

.hidden {
    opacity: 0;
    visibility: hidden;
    @include _visibility_transition_hide;
}

.active {
    color: $color-k-yellow !important;
}

.w100 {
    width: 100%;
}

.mw100 {
    min-width: 100%;
}

.mhCard {
    min-height: 250px;
}

.unread::after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: $color-k-red;
}