// Project style variables

// Main colors
$color-k-yellow: #A9933D;
$color-k-black: #121212;
$color-k-dark-grey: #191919;
$color-k-grey: #B5B5B5;
$color-k-grey-text: #929090;
$color-k-button: #C8952F;
$color-k-yellow: #A9933D;
$color-k-red: #810;
$color-black: hsl(0, 0%, 0%);
$color-white: #FFFFFF;
$color-trans-safe: rgba(0, 0, 0, 0);

$font-family: 'Montserrat', sans-serif;
$sider-box-shadow: none;
$navbar-max-height: 70px;
$calendar-icon: "../icons/calendar.svg";
$rating-icon: "../icons/rating.svg";
$triangle-icon: "../icons/triangle.svg";
$search-icon: "../icons/search.svg";
$arrow-icon: "../icons/arrow.svg";
$arrow-menu-icon: "../icons/arrowMenu.svg";
$star-empty-icon: "../icons/star.svg";
$star-filled-icon: "../icons/starFilled.svg";
$cross-icon: "../icons/cross.svg";
$home-icon: "../../icons/home.svg";
$signOut-icon: "../../icons/signout.svg";
$check-icon: "../icons/check.svg";
$eye-icon: "../icons/eye.svg";
$subs-inon: "../../icons/subscriptions.svg";
$nots-icon: "../../icons/notifications.svg";
$tos-icon: "../../icons/tos.svg";
$pin-icon: "../../icons/pin.svg";
$settings-icon: "../../icons/settings.svg";
$feedback-icon: "../../icons/feedback.svg";
$account-icon: "../../icons/account.svg";
$email-sent: "../../icons/email_sent.svg";
$done-icon: "../../icons/done.svg";
$reg-done-icon: "../../icons/reg_done.svg";
$password-changed-icon: "../../icons/password.svg";
$no-data-icon: "../../icons/no_data.svg";
$no-subs-icon: "../../icons/subscribe.svg";
$no-serach-results-icon: "../../icons/notFoundSearch.svg";