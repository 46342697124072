//Default input
.defaultInputWrapper {
    position: relative;
    margin-bottom: 5px;

    input, textarea {
        width: 100%;
        border-radius: 5px !important;
        border: 1px solid transparent !important;
        background-color: $color-k-black;
        font-weight: 700;
    }

    &.select {
        padding-bottom: 20px;
        font-size: 14px;

        .selectContainer, .selectControl {
            border-radius: 5px;
        }

        .singleValue, .selectPH {
            text-align: start;
            padding: 10px 10px;
        }
        
        .selectMenu {
            background-color: transparent;
            z-index: 500;
        }
    }

    .eyeButton {
        position: absolute;
        right: 0.75rem;
    }

    &.l {
        input {
            height: 50px;
            margin-bottom: 20px;
            padding: 13px 20px;
        }

        .eyeButton {
            top: 0.75rem;
        }
    }

    &.m {
        input {
            height: 40px;
            padding: 13px 20px;
            margin-bottom: 16px;
            font-size: 14px !important;
        }

        .eyeButton {
            top: 0.5rem;
        }
    }

    p {
        font-size: 0.8rem;
        position: absolute;
        bottom: 0;
        left: 0;
        color: $color-k-red;
    }

    &.error {
        input, textarea {
            border-color: $color-k-red !important;
        }

        textarea {
            &::-webkit-resizer {
                display: none;
            }
        }

        input {
            color: $color-k-red;
        }

        .eyeButton {
            &:before {
                background-color: $color-k-red !important;
            }
        }
    }

    &.selectError {
        .selectControl {
            border: 1px solid $color-k-red;
        }
    }

    &.password {
        input {
            padding-right: 2.5rem;
        }
    }
}

.searchWrapper {
    height: 50px;
}

.searchInput, .dateInput {
    position: relative;
    background-color: $color-k-dark-grey;

    input {
        height: 40px;
        font-size: 1rem;
        border: none;
        background-color: $color-trans-safe;
    }
}

.searchInput {
    width: 250px;

    @include _950 {
        width: 170px;
    }

    input {
        width: 100%;
        padding: 0 1.8rem 0 3.5rem;
        border-radius: 0;
    }

    &::before {
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        top: 10px;
        left: 25px;
        content: url($search-icon);
    }
}

.collapsableSearch {
    position: absolute !important;
    display: flex;
    align-items: center;
    top: calc(50% - 25px);
    left: 15px;
    height: 50px;
    line-height: 50px;
    padding-right: 50px;
    transition: all 0.5s ease;

    &::before {
        display: none;
    }

    input {
        position: relative;
        padding: 0;
        width: 0%;
        transition: all 0.3s ease;

        &.toggle {
            width: 90%;
            margin-left: 10px;
            border-bottom: 1px solid $color-k-grey;
            transition: all 0.5s 0.3s ease;
        }
    }

    &.toggle {
        width: 70%;
        transition: all 0.5s ease;
    }

    position: relative;
    width: 40px;
}

.dateInput {
    position: relative;
    background-color: $color-k-dark-grey;
    width: 250px;

    input {
        padding: 0 3.5rem 0 1rem;
    }

    @include _768 {
        width: 190px;

        input {
            padding: 0 2rem 0 0.5rem;
            font-size: 0.9rem;
        }
    }

    &::after {
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        top: 10px;
        right: 25px;
        content: url($calendar-icon);
    }
}

.orgSearchInput {
    position: relative;
    margin-top: 2rem;
    width: 85%;

    @include _650 {
        width: 100%;
    }

    input {
        height: 60px;
        width: 100%;
        background-color: $color-k-dark-grey;
        border: none;
        border: 1px solid $color-k-grey;
        border-radius: 5px;
        padding: 1rem 3.5rem 1rem 1rem;
    }

    &:not(.filled)::after {
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        right: 20px;
        top: 20px;
        content: "";
        background: url($search-icon) top left / cover no-repeat;
    }
    
    .closeButton {
        right: 17px;
    }
}

// Dropdown
.selectContainer {
    background-color: $color-k-dark-grey;
    color: $color-k-grey;
    font-weight: 700;
    border-radius: 5px;

    .singleValue {
        color: $color-k-grey;
        text-align: center;
        font-family: $font-family;
    }

    .selectList {
        background-color: $color-k-dark-grey;
    
        .selectItem {
            background-color: $color-k-dark-grey;

            &:hover {
                background-color: $color-k-black;
            }
        }
    }

    .selectControl {
        background-color: $color-k-dark-grey;
        box-shadow: none;
        border-radius: 5px;
        border: none;
        font-weight: 600;
        padding: 10px;
        z-index: 100;
    }

    .selected {
        color: $color-k-button;
        background-color: $color-k-dark-grey;
    }

    .focused {
        background-color: $color-k-black;
    }


    #react-select-3-listbox {
        z-index: 200 !important;
    }
}


//Rating
.ratingContainer {
    @include flexbox(flex, row);
    gap: 10px; 
    align-items: center;
    justify-content: flex-end;

    .rating, .rating .star {
        transform: rotate(180deg);
    }

    .rating {
        @include flexbox(flex, row);

        .star {
            cursor: pointer;
            padding: 0 20px;
            width: 23px;
            height: 21px;
            background: url($star-empty-icon) center no-repeat;
    
            &.rated, &:hover, &:hover ~ .star {
                background-image: url($star-filled-icon);
            }
        }
    }
}

.checkBoxInput {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &+label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        font-size: 12px;

        &::before {
            content: '';
            display: inline-block;
            width: 1rem;
            height: 1rem;
            flex-shrink: 0;
            flex-grow: 0;
            border: 2px solid $color-k-grey-text;
            margin-right: 0.5rem;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
        }
    }

    &.error {
        &+label{
            color: $color-k-red;
            
            &::before {
                border-color: $color-k-red;
            }
        }
    }

    &:checked+label::before {
        border-color: $color-k-button;
        background-color: $color-k-button;
        background-image: url($check-icon);
    }

    &:not(:disabled):not(:checked)+label:hover::before {
        border-color: $color-k-grey-text;
    }
    
    &:not(:disabled):active+label::before {
        background-color: $color-k-button;
    }

    &:disabled+label::before {
        background-color: $color-k-grey-text;
    }
}

.pincode-input-container {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid $color-k-button;

    .pincode-input-text {
        border: none !important;
        border-bottom: 1px solid $color-k-grey !important;
        border-radius: 0;
        width: 30px !important;
        height: 30px !important;
        margin: 10px !important;
    }
}