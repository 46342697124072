@mixin _bg_transiition {
    transition: background-color 0.3s ease-in-out;
}

@mixin _color_transiition {
    transition: color, border-color 0.3s ease-in-out;
}

@mixin _all_transiition {
    transition: 0.3s ease-in-out;
}

@mixin _border_bot_transiition {
    transition: border-bottom 0.3s ease-in-out;
}

@mixin _visibility_transition {
    transition: opacity 0.1s linear 0s, visibility 0s linear 0s;
}

@mixin _visibility_transition_hide {
    transition: visibility 0s linear 0.1s, opacity 0.1s linear 0s;
}

@mixin _box_shadow_transition {
    transition: box-shadow 0.1s ease-in-out;
}