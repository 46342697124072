.categoryList {
    @include _650 {
        width: 100% !important;
        background-color: $color-k-black !important;
        padding: 1rem !important;
        // margin: 1rem 0 !important;
    }

    li {
        font-weight: 600;
        color: $color-k-grey-text;
        text-decoration: none;
        @include _all_transiition;
        padding: 0.4rem 0;
        margin: 0.9rem 0;
        border-bottom: 2px solid $color-trans-safe !important;
        width: fit-content;

        &.active::before {
            background-color: $color-k-yellow;
        }

        &:hover {
            &.active::before {
                background-color: $color-k-yellow
            }

            color: $color-k-grey-text;
            border-bottom: 2px solid $color-k-red !important;
        }

        @include icon_mask('../../icons/triangle.svg', 13px, 15px, $color-k-grey-text, $color-k-grey-text, true);

        &::before {
            margin-top: 3px;
        }

        @include _768 {
            &::before {
                display: none;
            }

            margin: 0.5rem !important;
        }
    }
}

.horizontalVideoList, .verticalVideoList {
    position: relative;
    margin: 0 2rem;
}

.verticalVideoList {
    height: calc(100% - 70px);
    padding: 5px 0;
    display: block;

    .arrowElem {
        left: calc((100% - 25px/2)/2);
    }

    .arrow-top {
        top: -5px;
    }

    .arrow-bot {
        bottom: -50px;
    }

    .cardsWrapper {
        margin: 25px 0;
        @include _disable_scrollbar;
        height: 100%;
        flex-grow: 1;

        .cards {
            @include flexbox(flex, row, null, null, wrap, 0, 0);
            gap: 1rem;
        }
    }
}

.horizontalVideoList {
    @include flexbox(flex, column);
    gap: 2rem;

    .cardsWrapper {
        position: relative;

        .cards {
            @include flexbox(flex, row);
            @include _disable_scrollbar;
            overflow: scroll hidden;
            touch-action: pan-x;
            -ms-touch-action: pan-x;

            width: 100%;
            gap: 1rem;

            .gapFix {
                margin-left: -15px;
            }
        }
    }

    .arrowElem {
        top: calc((150px - 22px)/2);
    }

    .arrow-left {
        left: -24px;
    }

    .arrow-right {
        right: -24px;
    }
}

.organizationsList, .notificationsList {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1rem;
    margin: 2rem 0;
}

.organizationsList {
    .organizationCard {
        display: flex;
        flex-wrap: wrap;
        gap: 3rem;

        .body {
            font-size: 14px;
            background-color: $color-k-dark-grey;
            border-radius: 6px;
            flex-grow: 1;
            padding: 9px 20px;
            font-weight: 400;

            &.approved {
                font-weight: 600;
            }
        }

        .defaultButton {
            min-width: 11rem;
        }

        @include _768 {
            gap: 1rem;

            .defaultButton {
                min-width: 9rem;
            }
        }

        @include _650{
            justify-content: flex-end;

            .body {
                width: 100%;
            }

            .defaultButton {
                min-width: 8rem;
            }
        }
    }
}

.notsLegend {
    font-size: 17px;
    font-weight: 600;
    color: $color-k-grey-text;

    .read {
        opacity: .5;
    }
}

.notificationsList {
    font-size: 14px;
    font-weight: 400;
    
    .notificationCard {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        background-color: $color-k-dark-grey;
        color: $color-k-grey-text;
        border-radius: 6px;
        padding: 9px 20px;
        position: relative;

        &.unreadNotify {
            color: $color-k-grey;
            font-weight: 700;
        }
        
        .date, .source {
            flex-basis: 10rem;
            flex-grow: 0;
        }

        .text {
            flex-grow: 1;
            max-width: 600px;
        }
    }
}
