// Using
//.div {
//   @include _1170 {
//       @content;
//   }
//}

@mixin _1170 {
    @media (max-width: 1170px){
        @content;
    }
}
@mixin _950 {
    @media (max-width: 950px){
        @content;
    }
}
@mixin _768 {
    @media (max-width: 768px){
        @content;
    }
}
@mixin _650 {
    @media (max-width: 650px){
        @content;
    }
}
@mixin _480 {
    @media (max-width: 480px){
        @content;
    }
}
@mixin _350{
    @media (max-width: 350px){
        @content;
    }
}