@mixin _disable_scrollbar {
    overflow: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;

    &::-webkit-scrollbar {
        height: 0;
        width: 0;
        display: none;
    }
      
    &::-webkit-scrollbar-thumb:horizontal {
        display: none;
    }
}