#root, .frontContentPane {
    @include flexbox(flex, column);
}

#root {
    height: 100vh;
}

.logo {
    @include flexbox(flex, column, null, center);
    color: $color-white;
    font-size: 2rem;
    cursor: pointer;    
    background-image: url('../icons/logo.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 90px;
    width: 110px;
    margin: auto;

    @include _650 {
        font-size: 0.9rem;
        height: 50px;
        width: 100px;
    }
}

.logoBox {
    background-color: $color-k-dark-grey;
    box-shadow: $sider-box-shadow;
    margin-top: 1rem;
    margin-bottom: 2.5rem;

    &.front {
        @include _650 {
            margin-left: 35px !important;
        }
    }

    @include _650 {
        background-color: $color-k-black;
        width: unset;
        padding: 0;
        margin: 0;
        background-color: inherit;
    }
}

.videoWrapper {
    @include flexbox(flex, column, null, null, nowrap, 1, 1);

    padding: 1.5rem;
    gap: 35px;
    width: 100%;
    height: 100%;

    @include _650 {
        padding: 1rem;
    }

    .mainVideoInfo, .description {
        color: $color-k-grey-text;
        width: 100%;
    }

    .mainVideoInfo {
        @include flexbox(flex, row, null, null, wrap, 1, 0);
        justify-content: space-between;

        .videoTitle {
            position: relative;
            display: flex;
            color: $color-k-yellow;
            overflow-wrap: anywhere;

            h1 {
                padding-left: 1.5rem;
                font-size: 25px;
                font-weight: 700;
            }

            .backButton {
                top: calc(50% - 15px);
            }

            @include _650 {
                min-height: unset;
                padding: 0rem 1rem 1rem 0;
            }
        }

        .videoInfoPane {
            @include flexbox(flex, column, null, null, wrap, 1, 1);
            max-width: 30%;
            justify-content: space-between;
            padding-right: 1rem; 

            @include _1170 {
                max-width: 100%;
                padding-bottom: 2rem;
            }

            @include _650 {
                padding-right: 0;
            }

        }

        .additionalInfo {
            @include flexbox(flex, column, null, null, wrap, 0, 0);
            padding: 10px 0 10px 30px;
            font-size: 20px;
            gap: 30px;

            @include _650 {
                padding: 10px;
                gap: 1rem;
            }
        }

        .meta {
            @include flexbox(flex, row);
            gap: 15px;
        }
    }

    .description {
        padding-left: 30px;
        font-size: 20px;

        @include _650 {
            padding-top: 15px;
            padding-left: 0;
        }
    }
}

.contentWrapper {
    @include flexbox(flex, row, null, null, nowrap, 1, 1);

    @include _650 {
        flex-direction: column;
    }

    width: 100%;
    height: 100%;
}

.frontContentPane, .searchContentPane {
    height: calc(100% - $navbar-max-height);
    width: 100%;
}

.sider {
    background-color: $color-k-dark-grey;
    padding: 1rem;
    width: 250px;
    flex-shrink: 0;
    font-size: 17px;
    box-shadow: $sider-box-shadow;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    @include _disable_scrollbar;

    &:has(> div > .myAccountSider) {
        @include _950 {
            width: 30%;
        }

        @include _650 {
            display: none;
        }
    }

    @include _950 {
        width: 25%;
    }

    @include _768 {
        padding: 0.5rem;
    }
}

.contentSect {
    width: 80%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @include _disable_scrollbar;

    @include _650 {
        width: 100%;
    }
}

.searchContentPane {
    @include _650 {
        height: calc(100% - $navbar-max-height - 88px );

        .cards {
            justify-content: space-between;

            .videoCard {
                width: 46%;
                max-width: unset;
                min-width: unset;
                margin: 0;
                
                img {
                    box-shadow: none;
                }

                .metaBar {
                    flex-wrap: wrap;
                }
            }
        }
    }
}

.frontContentPane {
    gap: 30px;
    
    &:last-child {
        padding-bottom: 1rem;
    }
}

.authWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    .logo {
        height: 55px;
        margin: 1rem auto;
    }

    .auth {
        background-color: $color-k-dark-grey;
        max-width: 800px;
        width: 100%;
        margin: 1rem;
        padding: 4rem 9rem;
        position: relative;

        & > form > p, & > p {
            color: $color-k-yellow;
            margin: 0 0.1rem 1.5rem 0.1rem;
        }

        & > p {
            font-size: 14px;
            text-align: center;
        }

        & > form > p {
            font-size: 12px;
        }

        & > h3 {
            font-size: 20px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 20px;
            text-align: center;
        }

        > form > button {
            margin-top: 1rem;
        }

        @include _950 {
            padding: 2rem 4.5rem;
        }

        .tabNav {
            width: 100%;
            text-align: center;
            margin-top: 3rem;
            margin-bottom: 3rem;
            text-transform: uppercase;
            font-size: 20px;

            a {
                margin: 0 0.5rem;
                text-decoration: none;
                font-weight: 700;
                color: $color-k-grey-text;

                &.current {
                    color: $color-k-grey;
                }
            }
        }

        .loginLinks, .signUpTOS{
            display: flex;
            justify-content: space-between;
            gap: 1rem;

            a {
                color: $color-k-yellow;
            }
        }

        .loginLinks {
            margin: 1rem 1.35rem 2rem 1.35rem;
            font-size: 14px;
        }

        .signUpTOS {
            margin-bottom: 2rem;
            font-size: 12px;
        }

        .crossButton {
            position: absolute;
            top: 2rem;
            right: 2rem;
        }

        .fieldGroupDuo {
            display: flex;
            gap: 1.5rem;

            div {
                flex-grow: 1;
            }
        }

        @include _650 {
            padding: 1rem;

            .tabNav {
                font-size: 18px;
            }

            *input {
                font-size: 14px;
            }

            .crossButton {
                top: 1rem;
                right: 1rem;
            }

            .fieldGroupDuo {
                display: block;
            }
        }
    }
}

.myAccountSider {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .navLink {
        font-weight: 600;
        color: $color-k-grey-text;
        text-decoration: none;
        @include _all_transiition;
        padding: 0.4rem 0;
        margin: 0.4rem 0;
        border-bottom: 2px solid $color-trans-safe !important;
        width: fit-content;

        &.active::before {
            background-color: $color-k-yellow;
        }

        &:hover {
            &.active::before {
                background-color: $color-k-yellow
            }

            color: $color-k-grey-text;
            border-bottom: 2px solid $color-k-red !important;
        }
    }

    .subscriptionsLink {
        @include icon_mask($subs-inon, 20px, 20px, $color-k-grey-text, $color-k-grey-text, true)
    }

    .notificationsLink {
        @include icon_mask($nots-icon, 20px, 20px, $color-k-grey-text, $color-k-grey-text, true)
    }

    .userdataLink {
        @include icon_mask($settings-icon, 20px, 20px, $color-k-grey-text, $color-k-grey-text, true)
    }

    .feedbackLink {
        @include icon_mask($feedback-icon, 24px, 24px, $color-k-grey-text, $color-k-grey-text, true)
    }

    .tosLink {
        @include icon_mask($tos-icon, 20px, 20px, $color-k-grey-text, $color-k-grey-text, true)
    }

    .homeLink {
        @include icon_mask($home-icon, 20px, 20px, $color-k-grey-text, $color-k-grey-text, true)
    }
}

.accountWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 1.5rem 2rem;
}

.accountSubmenu {
    width: 100% !important;
    background-color: $color-k-black !important;

    .selectItem {
        margin: 10px 0;
    }

    .selectMenu {
        z-index: 200;
    }
}

.subscriptions {
    > div > p,  > form > p {
        margin: 2rem 0;
        font-weight: 500;
    }

    > form {
        .defaultInputWrapper {
            max-width: 80%;
            margin-top: 10px;

            @include _650 {
                max-width: unset;
            }

            input {
                background-color: $color-k-dark-grey;
            }
        }

        .defaultButton {
            width: 10rem;
            min-width: unset;
        }
    }
}

.userDataForms {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .innerWrapper {
        form {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            gap: 2rem;

            @include _650 {
                justify-content: end;
            }

            > div {
                flex-grow: 1;
            }

            > button, .buttonGroup button {
                width: 160px;
            }

            .buttonGroup {
                flex-grow: 0;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }

            > button {
                flex-grow: 0;
                margin-bottom: 22px;
            }
        }

        * input {
            background-color: $color-k-dark-grey;

            &::placeholder {
                font-weight: 400;
            }
        }

        .formHeding {
            font-weight: 700;
            margin-bottom: 1.5rem;
            color: $color-k-grey;
        }

        > p {
            color: $color-k-grey-text;
        }
    }
}

.feedbackForm {
    .innerWrapper form{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 2rem;

        @include _650 {
            justify-content: end;
        }

        > div {
            flex-grow: 1;
        }

        * input, * textarea {
            &::placeholder {
                font-weight: 400 !important;
            }
        }

        .selectPH {
            font-weight: 400 !important;
        }

        .selectControl {
            padding: 0;
        }

        > button {
            flex-grow: 0;
            margin-bottom: 22px;
            width: 160px;
        }

        textarea {
            padding: 16px;
            margin-bottom: 16px;
            font-size: 14px;
            background-color: $color-k-dark-grey;
            resize: vertical;
        }
    }
}

.connectWrapper {
    margin: auto;
    padding: 1rem;
    border-radius: 6px;
    position: relative;

    .connectInner {
        background-color: $color-k-dark-grey;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        text-align: center;
        padding: 3rem 4rem;

        .errMsg {
            margin-bottom: 0;
        }
    }

    @include _650 {
        width: 100%;

        .connectInner {
            padding: 1rem;
        }
    }
}