
html, input, textarea {
    box-sizing: border-box;
    font-size: 16px;
    color: $color-k-grey;
    outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: 5000s ease-in-out 0s;
}
  
*, *:before, *:after {
    box-sizing: inherit;
    outline: none;
}
  
body, code, h1, h2, h3, h4, h5, h6, p, ol, ul, input, button, textarea {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h2 {
    color: $color-k-button;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
}

h3 {
    font-size: 17px;
    font-weight: 500;
    margin: 0.5rem 0;
}

body {
    background-color: $color-k-black;
    height: 100%;
}

ol, ul {
    list-style: none;
}
  
img, iframe {
    max-width: 100%;
    height: auto;
}