@mixin icon_mask(
    $icon: null,
    $width: 1rem,
    $height: 1rem,
    $color: $color-k-grey,
    $hoverColor: null,
    $side: false
   ) {
    cursor: pointer;
    position: relative;
    @if $side == false {
        width: $width;
        height: $height;
    } @else {
        margin-left: calc($width + 1rem);
        // line-height: 0.8;
    }
    background-color: $color-trans-safe;
    border: none;
    z-index: 1;

    &::before {
        position: absolute;
        width: $width;
        height: $height;
        @if $side == false {
            left: 0;
            top: 0;
        } @else {
            left: calc(0px - $width - 1rem);
        }
        content: "";
        -webkit-mask: url($icon) no-repeat 100% 100%;
        mask: url($icon) no-repeat 100% 100%;
        -webkit-mask-size: cover;
        mask-size: cover;
        background-color: $color;
        @include _bg_transiition;
    }

    @if $hoverColor != null {
        &:hover::before {
            background-color: $hoverColor;
        }
    }
}

.eyeButton {


    &.toggled {
        &::before {
            background-color: $color-k-yellow;
        }
    }
}