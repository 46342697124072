.iframeWrapper {
    max-width: 70%;

    @include _1170 {
        max-width: 100%
    }

    width: 100%;
}

.iframeContainer {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        border: none;
        // background: url('../../public/placeholder640-360.jpg');
        background-size: cover;
    }
}