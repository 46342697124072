.navbar {
    position: relative;
    width: 100%;
    height: $navbar-max-height;

    @include flexbox(flex, row, null, null, nowrap, 0, 0, flex-start, flex-end);

    @include _650 {
        align-items: center;
        background-color: $color-k-dark-grey; 
    }
}

.navBox {
    padding: 1rem;
    @include flexbox(flex, row, null, null, wrap, 0, 0, flex-start, space-between);
    gap: 30px;

    @include _950 {
        gap: 10px;
    }

    @include _650 {
        align-items: center;
        width: 100%;
    }
}

.bm-burger-button {
    display: block;
    position: relative;
    width: 36px;
    height: 30px;
    padding: 1rem;
}

.bm-burger-bars {
    background: $color-k-grey;
    height: 7% !important;
}

.bm-burger-bars-hover {
    background: $color-k-button;
}

.bm-cross-button {
    button {
        height: 50px !important;
        width: 50px !important;
        z-index: 9999 !important;
    }
}

.bm-cross {
    right: 1rem;
    top: 0.7rem;
}

.bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 0;
}

.bm-menu {
    background: $color-k-dark-grey;
    font-size: 1.15em;
}

.bm-morph-shape {
    fill: $color-k-dark-grey;
}

.bm-item-list {
    color: $color-k-grey-text;

    &:first-child {
        margin-top: 80px;
    }
}

.bm-item {
    display: inline-block;
    padding: 1rem;
}

.bm-item-list:first-child {
    .logo {
        padding: 1rem;
        height: $navbar-max-height;
    }
}

.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    right: 0;
}