.pagination {
    color: $color-k-grey;
    font-size: 20px;
    font-weight: 700;

    ul {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        gap: 10px;
    }

    a {
        display: block;
        padding: 5px 10px;
        cursor: pointer;
    }

    li {
        &.previous, &.next {
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            a {
                width: 12px;
                height: 20px;
                @include icon_mask("../#{$arrow-icon}", 12px, 20px, $color-k-grey, $color-k-yellow);

                &::before {
                    top: 0px;
                }
            }
        }

        &.previous a::before {
            rotate: 180deg;
        }

        &.next a::before {
            left: unset;
            right: 0;
        }

        &.disabled {
            color: $color-k-grey-text;

            a {
                cursor: default;
                
                &::before {
                    background-color: $color-k-grey-text;
                }
                
                &:hover::before {
                    background-color: $color-k-grey-text;;
                }
            }
        }

        &.selected {
            color: $color-k-button;
        }

        &:hover {
            color: $color-k-grey-text;
        }
    }
}