$datepicker__background-color: $color-k-dark-grey !default;
$datepicker__border-color: $color-k-dark-grey !default;
$datepicker__highlighted-color: #fff !default;
$datepicker__muted-color: $color-k-grey !default;
$datepicker__selected-color: $color-k-button !default;
$datepicker__text-color: $color-k-grey-text !default;
$datepicker__header-color: $color-k-grey-text !default;
$datepicker__navigation-disabled-color: lighten(
  $datepicker__muted-color,
  10%
) !default;
$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.8rem !default;
$datepicker__font-family: $font-family !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-button-size: 32px !default;
$datepicker__triangle-size: 8px !default;
